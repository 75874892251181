<template>
  <div class="bg-white p-5">
    <shop-advert isShop/>
  </div>
</template>

<script>
import shopAdvert from '../../advert-position/adverts/shop-advert.vue'
export default {
  components: { shopAdvert },

}
</script>

<style>

</style>